import React from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { Products } from "../components/products";
import { Academy } from "../components/academy";
import { Resellers } from "../components/resellers";
import { Contact } from "../components/contact";
import { Footer } from "../components/footer";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const Home = (props) => {

  return (
    <div id="alientech-ar-home">
        <Navigation data={props.data.Navigation}/>
        <Header data={props.data.Header} />
        <Products data={props.data.Products} />
        <Academy data={props.data.Academy} />
        <Resellers data={props.data.Resellers} />
        <Contact data={props.data.Contact} />
        <Footer data={props.data.Footer} />
    </div>
  );
};

export default Home;
