import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="video-overlay">
          <video
            src={props.data ? props.data.video : "Loading"}
            type="video/mp4"
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-10 intro-text">
                <h1>
                <span className="title-accent">{props.data ? props.data.title1 : "Loading"}</span>{" "}<span>{props.data ? props.data.title2 : "Loading"}</span>
                  <span></span>
                </h1>
                <h2>
                  <span>{props.data ? props.data.title3 : "Loading"}</span>
                  <span></span>
                </h2>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#products-services"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  {props.data ? props.data.action : "Loading"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
