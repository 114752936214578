import React from 'react';

export const Footer = (props) => {

  const showSocial = props.showSocial === false ? false : true;
  const currentYear = new Date().getFullYear().toString();

  return (
    <div id="footer">
      { showSocial ? (
        <div className="container">
          <div className="col-md-12">
            <div className="social">
              <div className="row">
                <ul>
                    <li>
                      <a
                        href={props.data ? props.data.facebookUrl : "/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                    </a>
                    </li>
                    <li>
                      <a
                        href={props.data ? props.data.instagramUrl : "/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (<></>)
      }
      <div id="copyright">
        <div className="container text-center">
          <p>Alientech Argentina {currentYear} &copy; Todos los derechos reservados.</p>
        </div>
      </div>
    </div>
  );
};
