import React from "react";
import { Navigation } from "../components/navigation";
import { Footer } from "../components/footer";

export const NotFound = (props) => {

  return (
    <div id="alientech-ar-not-found">
      <Navigation data={props.data.Navigation} explicitUrls={true} />
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Oops! No encontrado</h3>
            <h1><span>4</span><span>0</span><span>4</span></h1>
          </div>
          <h2>Lo sentimos, la página solicitada no existe</h2>
        </div>
      </div>
      <Footer data={props.data.Footer} showSocial={false} />
    </div>
  );
};

export default NotFound;
