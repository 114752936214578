import React, { useState, useEffect } from 'react';
import { apiShop } from "../api/api";

export const Academy = (props) => {

  const [records, changeRecords] = useState()
  const [recordsEmpty, changeEmptyRecords] = useState()

  useEffect(() => {
    const academyCategory = String(process.env.REACT_APP_API_SHOP_CATEGORY_ID);
    // Machete de categorias:
    // 119 -> powergate
    // 120 -> ecm-titanium
    // 121 -> kess-3
    // 123 -> academy
    const loadCourses = () => {

      changeEmptyRecords(false);
      changeRecords(null);  

      apiShop
        .get("/products?category=" + academyCategory + "&status=publish")
        .then((response) => {
          const returnedData = response;
          const r = {};
          const resultsLength = returnedData.data.length;
          if ( resultsLength > 0 ) {
            returnedData.data.forEach(record => {
              r[record.id] = record;
            })
            changeRecords(r);
            changeEmptyRecords(false);
          } else {
            changeRecords(null);
            changeEmptyRecords(true);
          }
        })
        .catch((error) => {
          changeRecords(null);
          changeEmptyRecords(true);
          console.error(error);
        });
    }
    loadCourses();

  }, [changeRecords, changeEmptyRecords]);
  return (
    <div id="academy" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            {props.data ? props.data.title : "Loading"}
          </h2>
          <h3>{props.data ? props.data.subTitle : "Loading"}</h3>
          <p>
            {props.data ? props.data.description : "Loading"}
            <br />
            <br />
            {props.data ? props.data.description2 : "Loading"}
          </p>
        </div>
        <div className="row">
          {records ? (
              (Object.values(records) || []).map(record => {
                return (
                  <div key={record.id} className="academy-item">
                    <div className="thumbnail">
                      {( record.images.length > 0 ?
                          <img src={record.images[0].src} alt="..." className="academy-img" />
                        :
                          <img src={props.data.defaultImg} alt="..." className="academy-img" />
                      )}
                      <div className="caption">
                        <p>
                          <i className="fa fa-calendar academy-sep" aria-hidden="true" />
                          {( record.attributes.date && record.attributes.date != null ?
                              <span>{record.attribute.date}</span>
                            :
                              <span>{props.data.noDateText}</span>
                          )}
                        </p>
                        <h4>{record.name}</h4>
                      </div>
                      <a
                        href={record.permalink}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-custom btn-lg page-scroll"
                      >                      
                        {props.data.buttonText}
                      </a>
                    </div>
                  </div>
                )}
              )) : (
                recordsEmpty ? (
                  <div id="norecords" className="academy-item">
                    <div className="thumbnail">
                      <i className="fa fa-exclamation-triangle icon-font-size academy-img" aria-hidden="true"></i>
                      <div className="caption">
                        <p>{props.data ? props.data.noResultsText : "Loading"}</p>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div id="loading" className="academy-item">
                    <div className="thumbnail">
                      <i className="fa fa-gear fa-spin icon-font-size academy-img" aria-hidden="true"></i>
                      <div className="caption">
                        <p>{props.data ? props.data.loadingText : "Loading"}</p>
                      </div>
                    </div>
                  </div>
              )
            )            
          }
        </div>
      </div>
    </div>
  );
};
