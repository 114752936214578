import React from "react";

export const Products = (props) => {
  return (
    <div id="products-services" className="text-center">
      <div className="container">
        <div className="row ps-country">
          <h2 className="ps-title">
            <span>Alientech</span>
            <span>Argentina</span>
          </h2>
          <p className="ps-title-desc">{props.data ? props.data.titleDesc : "Loading"}</p>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <h3>{props.data ? props.data.subTitle : "Loading"}</h3>
          <p>{props.data ? props.data.mainDesc : "Loading"}</p>
        </div>
        <div className="row ps-items-container">
          {props.data
            ? props.data.products.map((d, i) => (
                <div key={`${d.title}-${i}`} className="">
                  {" "}
                  <a href={d.url} target="_blank" rel="noreferrer">
                    <img className="ps-items-image" src={d.image} alt={d.title} />
                  </a>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
      <div className="ps-equipment-container">
        <span className="ps-equipment-text">
          <span className="ps-equipment-text-1">
            <span>Lea&nbsp;-&nbsp;</span><span>Modifique</span><span>&nbsp;-&nbsp;Escriba</span>
          </span>
          <br />
          <span className="ps-equipment-text-2">
            <span>Pruebe&nbsp;-&nbsp;Repita</span>
          </span>
        </span>
        <img
          className="ps-equipment-image"
          alt="Alientech Equipment"
          src="../img/products/products-action.webp"
        />
      </div>
    </div>
  );
};
