import React, { useState } from 'react';
import CustomModal from './utils/custom_modal.jsx'
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';

export const Resellers = (props) => {

  const googleMapsSiteKey = String(process.env.REACT_APP_GOOGLE_MAPS_SITE_KEY);
  const branchData = props.data ? props.data.branches : [];

  const [showModal, changeShowModal] = useState(false);
  const [activeBranchName, changeActiveBranchName] = useState("No name");
  const [activeBranchData, changeActiveBranchData] = useState(<></>);

  const handleClick = (branch) => {

    const phone = (branch.phone ? branch.phone : props.data.branch_default_phone);
    const email = (branch.email ? branch.email : props.data.branch_default_email);

    changeActiveBranchName(branch.name);
    changeActiveBranchData(
      <table id="modal-branch-data">
        <tbody>
          <tr>
            <td><i className={"fa fa-phone icon-font-size"} aria-hidden="true" /></td><td>Teléfono:</td><td>{phone}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-envelope icon-font-size"} aria-hidden="true" /></td><td>Email:</td><td>{email}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-building icon-font-size"} aria-hidden="true" /></td><td>Dirección:</td><td>{branch.address}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-map-marker icon-font-size"} aria-hidden="true" /></td><td>Localidad:</td><td>{branch.city_name + ",\n" + branch.province_name}</td>
          </tr>
          <tr>
            <td><i className={"fa fa-globe icon-font-size"} aria-hidden="true" /></td><td>País:</td><td>{branch.country_name}</td>
          </tr>
        </tbody>
      </table>
    )

    changeShowModal(true);
  }

  return (
    <div id="resellers" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>
            {props.data ? props.data.title : "Loading"}
          </h2>
          <p>
            {props.data ? props.data.description : "Loading"}
          </p>
        </div>
      </div>
      { /* Important! Always set the container height explicitly */ }
      <div id="resellers-map-container" className="map-container" style={{ height: "60vh" }}>
        <APIProvider apiKey={googleMapsSiteKey}>
          <Map
            mapId='ALIENTECH_AR_RESELLERS'
            defaultZoom={props.data ? props.data.map_default_zoom : 1}
            defaultCenter={props.data ? props.data.map_default_center : {lat: -33.8567844, lng: 151.213108}}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
          >
          {
            branchData.map((branch) => (

              (branch.show_in_map ? (
                <AdvancedMarker
                  key={branch.id}
                  position={branch.position}
                  clickable={true}
                  onClick={() => handleClick(branch)}
                >
                  <img
                    src="img/cropped-favicon-192x192.png"
                    className="map-custom-marker"
                    alt={branch.name}
                  />
                </AdvancedMarker>
              ) : <></>)
            ))              
          }
          </Map>
        </APIProvider>
      </div>
      <CustomModal
        show={showModal}
        onHide={() => changeShowModal(false)}
        title={activeBranchName}
        body={activeBranchData}
      />
    </div>
  );
};
