import React from "react";

export const Navigation = (props) => {

  const explicitUrls = props.explicitUrls === true ? true : false;

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            href="/">
            <img
              id="company-logo"
              src="img/alientech_logotype_dark_background.webp"
              alt="Alientech Argentina"
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href={explicitUrls ? "/#products-services" : "#products-services"} className="page-scroll">
                Productos y Servicios
              </a>
            </li>
            <li>
              <a href={explicitUrls ? "/#academy" : "#academy"} className="page-scroll">
                Academy
              </a>
            </li>
            <li>
              <a href={props.data ? props.data.shopUrl : "Loading"} target="_blank" rel="noreferrer" className="page-scroll">
                Shop
              </a>
            </li>
            <li>
              <a href={props.data ? props.data.supportUrl : "Loading"} target="_blank" rel="noreferrer" className="page-scroll">
                Soporte
              </a>
            </li>
            <li>
              <a href={explicitUrls ? "/#resellers" : "#resellers"} className="page-scroll">
                Resellers
              </a>
            </li>
            <li>
              <a href={explicitUrls ? "/#contact" : "#contact"} className="page-scroll">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
