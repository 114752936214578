import React, { useState } from 'react';
import { apiContact } from "../api/api";
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {

  const messageLimit = 256;
  const [{ name, email, message }, setState] = useState(initialState);

  const [newForm, changeNewForm] = useState(true);
  const [formValidName, changeFormValidName] = useState(false);
  const [formValidEmail, changeFormValidEmail] = useState(false);
  const [formValidMessage, changeFormValidMessage] = useState(false);
  const [messageSize, changeMessageSize] = useState(messageLimit);
  const [formSending, changeFormSending] = useState(false);
  const [formSent, changeFormSent] = useState(false);
  const [formError, changeFormError] = useState(false);

  const captchaSiteKey = String(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  const [captchaCode, changeCaptchaCode] = useState("");


  const containsHTML = (text) => {

    const HtmlRegex = /<[a-z][\s\S]*>/i;
    const urlRegex = /([a-zA-Z\d]+:\/\/)?(\w+:\w+@)?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(\/.*)?/i;
    return HtmlRegex.test(text) || urlRegex.test(text);
  }


  const handleChange = (e) => {
 
    const field = e.currentTarget;
    const { name, value } = field;

    switch(field.name) {
      case 'name':
        changeFormValidName(field.checkValidity () && field.value.trim() !== "" && !containsHTML(field.value));
        break;
      case 'email':
        changeFormValidEmail(field.checkValidity());
        break;
      case 'message':
        changeFormValidMessage(field.checkValidity() && field.value.trim() !== "" && !containsHTML(field.value));
        changeMessageSize(messageLimit - field.value.length);
        break; 
      default:
        break;
    }

    setState((prevState) => ({ ...prevState, [name]: value }));
    changeNewForm(false);
  };


  const handleChangeCaptcha = (value) => {

    changeCaptchaCode(value);
  };


  const validateForm = (form) => {

    let validName = form.name.checkValidity() && form.name.value.trim() !== "" && !containsHTML(form.name.value);
    let validEmail = form.email.checkValidity();
    let validMessage = form.message.checkValidity() && form.message.value.trim() !== "" && !containsHTML(form.message.value);
    let invalidCaptcha = captchaCode === null || captchaCode.length === 0;

    if (form.checkValidity() === false ||
        !validName ||
        !validEmail ||
        !validMessage ||
        invalidCaptcha) {

      changeFormValidName(validName);
      changeFormValidEmail(validEmail);
      changeFormValidMessage(validMessage);
      let validationErrorMessage = "";
      if (!validName) {
        validationErrorMessage+="\u{2022} Nombre: " + (containsHTML(form.name.value) ? "no se permite código" : "debe completar este campo") + ".\n";
      }
      if (!validEmail) {
        validationErrorMessage+="\u{2022} Email: " + (form.email.validity.valueMissing ? "debe completar este campo" : "no es un email válido") + ".\n";
      }
      if (!validMessage) {
        validationErrorMessage+="\u{2022} Mensaje: " + (containsHTML(form.message.value) ? "no se permite código" : "debe completar este campo") + ".\n";
      }
      if (invalidCaptcha) {
        validationErrorMessage+="\u{2022} Captcha: debe completar el challenge.\n";
      }
      toast.error(validationErrorMessage);

      return false;
    }

    return true;
  }


  const handleSubmit = (e) => {

    e.preventDefault();
    e.stopPropagation();

    changeNewForm(false);
    if (!validateForm(e.currentTarget)) {

      return;
    }

    var FormData = require("form-data");
    let clientContactData = new FormData();
    clientContactData.append("client-name", name);
    clientContactData.append("client-email", email);
    clientContactData.append("client-msg", message);
    clientContactData.append("g-recaptcha-response", captchaCode);
  
    //Log clientContactData
    //for(var pair of clientContactData.entries()) {
    //  console.log("\"" + pair[0] + "\", \"" + pair[1] + "\"");
    //}

    changeFormSending(true);
    changeFormSent(true);
    changeFormError(false);

    apiContact
    .post("/contact_alientech_ar", clientContactData)
    .then((response) => {
      if (response.data && !response.data.ok) {

        changeFormSending(false);
        changeFormSent(true);
        changeFormError(true);
        console.error(response.data.message);
        toast.error(response.data.message);
      }
      else {

        changeFormSending(false);
        changeFormSent(true);
        changeFormError(false); 
        toast.success(props.data.SuccessText);
      }
    })
    .catch((error) => {

      changeFormSending(false);
      changeFormSent(true);
      changeFormError(true);
      console.error(error);
      toast.error(props.data.unknownErrorText);
    });
  };


  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contáctenos</h2>
                <p>
                  Por favor llene el formulario para enviarnos un email y
                  nos contactaremos con usted lo más pronto posible.
                </p>
              </div>
              { !formSent ? (
              <form name="contact_form" noValidate onSubmit={handleSubmit}>
                <fieldset disabled="">
                  <div className="row">
                    <div className="col-md-6">
                      <div className={(newForm ? "" : (formValidName ? "has-success" : "has-error")) + " form-group"}>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-user" />
                            </span>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Nombre"
                            maxLength = "64"
                            required
                            onChange={handleChange}
                          />
                          <span className={"glyphicon " + (formValidName ? "glyphicon-ok" : "glyphicon-remove") + " form-control-feedback"} aria-hidden="true"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={(newForm ? "" : (formValidEmail ? "has-success" : "has-error")) + " form-group"}>
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-envelope" />
                          </span>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            maxLength = "64"
                            pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                            required
                            onChange={handleChange}
                          />
                          <span className={"glyphicon " + (formValidEmail ? "glyphicon-ok" : "glyphicon-remove") + " form-control-feedback"} aria-hidden="true"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={(newForm ? "" : (formValidMessage ? "has-success" : "has-error")) + " form-group"}>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <i className="glyphicon glyphicon-pencil" />
                      </span>
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="4"
                        placeholder="Mensaje"
                        maxLength = {messageLimit}
                        required
                        onChange={handleChange}
                      />
                      <span className={"glyphicon " + (formValidMessage ? "glyphicon-ok" : "glyphicon-remove") + " form-control-feedback"} aria-hidden="true"></span>
                    </div>
                    <span className="help-block">Máximo 256 caracteres ({messageSize} restantes).</span>
                  </div>
                  <ReCAPTCHA
                    sitekey={captchaSiteKey}
                    onChange={handleChangeCaptcha}
                  />
                  <button type="submit" className="btn btn-custom btn-lg">
                    Enviar Mensaje
                  </button>
                </fieldset>
              </form>
              ) : (
                  formSending ? (
                    <div id="sending">
                      <div className="icon-container">
                        <i className="fa fa-gear fa-spin icon-font-size" aria-hidden="true"></i>
                        <div className="caption">
                          <p>{props.data ? props.data.sendingText : "Loading"}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    !formError ? (
                      <div id="sending">
                      <div className="icon-container">
                        <i className="fa fa-check-circle icon-font-size" aria-hidden="true"></i>
                        <div className="caption">
                          <p>{props.data ? props.data.sentText : "Loading"}</p>
                        </div>
                      </div>
                    </div>
                    ) : (
                      <div id="error">
                      <div className="icon-container">
                        <i className="fa fa-exclamation-triangle icon-font-size" aria-hidden="true"></i>
                        <div className="caption">
                          <p>{props.data ? props.data.errorText : "Loading"}</p>
                        </div>
                      </div>
                    </div>
                    )
                  )
                )
              }
            </div>
          </div>
          <div id="contact-info" className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección
                </span>
                {props.data ? props.data.addressLine1 : "loading"}<br />
                {props.data ? props.data.addressLine2 : "loading"}<br />
                {props.data ? props.data.addressLine3 : "loading"}<br />
                {props.data ? props.data.addressLine4 : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-whatsapp"></i> Chat (soporte y ventas)
                </span>{" "}
                <a
                  href={props.data ? props.data.whatsappUrl : "loading"}
                  target="_blank"
                  rel="noreferrer"
                >                  
                  {props.data ? props.data.whatsapp : "loading"}
                </a>
              </p>
            </div>           
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};
