import Axios from "axios";

export const apiShop = Axios.create({
  baseURL: String(process.env.REACT_APP_API_SHOP_ROOT),
  auth: {
    username: String(process.env.REACT_APP_API_SHOP_CLIENT_KEY),
    password: String(process.env.REACT_APP_API_SHOP_CLIENT_SECRET)
  }
});

export const apiContact = Axios.create({
    baseURL: String(process.env.REACT_APP_API_CONTACT_ROOT),
  });
