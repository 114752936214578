import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../scss/custom.scss";

export default class CustomModal extends Component {

  render () {
    return (
        <Modal
          {...this.props}
          show={this.props.show}
          onHide={this.props.onHide}
          animation={false}
          backdrop="static"
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.body}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
    )
  }
}
